<template>
  <div
    ref="grid"
    :class="{
      'contentGrid--tweening': isTweening
    }"
    class="contentGrid"
  >
    <!-- TODO Why component has defaultly hover state -->
    <component
      v-for="item in items"
      ref="items"
      :key="item.id"
      :is="`grid-${item.media.type}`"
      :content="item"
      :admin="admin"
      :file-key="'gridFile'"
      hoverable
      :class="{
        'contentGrid__item--vic': userRole === 'vic'
      }"
      @youtube-ready="onYoutubeReady"
      @loaded="onLoaded"
      class="contentGrid__item"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import gsap from 'gsap'
import GridImage from '@/components/component/grid-image'
import GridVideo from '@/components/component/grid-video'
import GridYoutube from '@/components/component/grid-main-player'

export default {
  name: 'ContentGrid',
  components: {
    GridImage,
    GridVideo,
    GridYoutube
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    admin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      animationItems: [],
      isTweening: true
    }
  },
  computed: {
    ...mapState({
      userRole: state => state.userRole
    })
  },
  mounted() {
    if (!this.$refs.items) return;
    this.animationItems = this.$refs.items
      .sort((a, b) => {
        const { left: aLeft, top: aTop } = a.$el.getBoundingClientRect();
        const { left: bLeft, top: bTop } = b.$el.getBoundingClientRect();
        return aTop === bTop ? aLeft - bLeft : aTop - bTop;
      })
      .filter((item) => item.$vnode.componentOptions.tag !== "GridYoutube")
      .reduce((acc, item) => {
        return [...acc, ...item.$children.map((child) => child.$refs.inner)];
      }, []);
    gsap.set(this.animationItems, { opacity: 0 });
    if (this.items.every((item) => item.media.type !== 'youtube')) {
      this.onYoutubeReady()
    }
  },
  methods: {
    onYoutubeReady() {
      gsap.fromTo(
        this.animationItems,
        {
          opacity: 0
        },
        {
          duration: 0.25,
          opacity: 1,
          ease: 'power1.inOut',
          stagger: {
            amount: 1.5
          },
          onComplete: () => {
            this.isTweening = false
          }
        }
      )
    }
  }
}
</script>

<style lang="scss">
.contentGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 8.65vw;
  grid-row-gap: 4px;
  row-gap: 4px;
  grid-column-gap: 4px;
  column-gap: 4px;
  // grid-auto-flow: column;
  // margin-bottom: 10px;

  &--tweening {
    pointer-events: none;
  }

  @media screen and (min-width: 400px) {
    grid-auto-rows: 8.95vw;
  }

  @media screen and (min-width: 640px) {
    grid-auto-rows: 9.35vw;
  }

  @media screen and (min-width: 768px) {
    --gap: clamp(4px, 0.3vw, 10px);
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 4.8vw;
    grid-row-gap: 10px;
    row-gap: var(--gap, 10px);
    grid-column-gap: 10px;
    column-gap: var(--gap, 10px);
    grid-auto-flow: unset;
  }

  @media screen and (min-width: 960px) {
    grid-auto-rows: 5vw;
  }

  @media screen and (min-width: 1440px) {
    grid-auto-rows: 5.2vw;
  }

  @media screen and (min-width: 1680px) {
    grid-auto-rows: 5.35vw;
  }

  &__item {
    cursor: pointer;
    grid-column: var(--col) / span var(--width);
    grid-row: var(--row) / span var(--height);
  }
}
</style>
